import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Container from '../components/Container/Container';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import styles from './styles/Resources.module.css';

const Resources = () => {
  return (
    <div className={styles.resources}>
      <div className={styles.navbar}>
        <Navbar />
      </div>

      <div className={styles.content}>
        <Container>
          <h2>Resources</h2>
          <div className={styles.brands}>
            <div className={styles.brand}>
              <StaticImage src='../images/brands/asics_logo.png' alt='Asics Logo' />
              <p>Asics</p>
            </div>
            <div className={styles.brand}>
              <StaticImage src='../images/brands/drComfort_logo.jpg' alt='Dr. Comfort Logo' />
              <p>Dr Comfort</p>
            </div>
            <div className={styles.brand}>
              <StaticImage src='../images/brands/revere_logo.png' alt='Revere Logo' />
              <p>Revere Comfort Shoes</p>
            </div>
            <div className={styles.brand}>
              <StaticImage src='../images/brands/vionic_logo.jpg' alt='Vionic Logo' />
              <p>Vionic Footwear</p>
            </div>
            <div className={styles.brand}>
              <StaticImage src='../images/brands/shoeClinic_logo.png' alt='Shoe Clinic Logo' />
              <p>Shoe Clinic</p>
            </div>
            <div className={styles.brand}>
              <StaticImage src='../images/brands/shoeScience_logo.png' alt='Shoe Science Logo' />
              <p>Shoe Science</p>
            </div>
            <div className={styles.brand}>
              <StaticImage src='../images/brands/podiatryNZ_logo.jpg' alt='Podiatry NZ Logo' />
              <p>Podiatry NZ</p>
            </div>
            <div className={styles.brand}>
              <StaticImage src='../images/brands/ganleyOrthotics_logo.png' alt='Ganley Orthotics Logo' />
              <p>Ganley Orthotics</p>
            </div>
          </div>
        </Container>
      </div>

      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
}

export default Resources;